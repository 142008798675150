import { reactive, computed } from 'vue'
import { createClient } from '@supabase/supabase-js'

const client = createClient('https://gwebyuywgowjtkhchwep.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTY0MTg3NTU1OSwiZXhwIjoxOTU3NDUxNTU5fQ.kTc5kQbajdfpIy7tYdAw1yB_eegDHdsgDlru8tBkMLU');

const store = {
    debug: true,

    state: reactive({
        session: {
            
        },
        message: 'Hello!',
        val: 1,
        message2: computed(() => store.state.val + 1),
    }),

    isLoggedIn() {
        const session = client.auth.session();

        return session != null;
    },

    increment() {
        this.state.val++
    }

}

export { store }
