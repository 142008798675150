<template>
	<div class="layout-topbar">
		<a href="https://hyperheadshots.com" class="layout-topbar-logo">
			<!-- <img alt="Logo" :src="topbarImage()" /> -->
			<!-- <svg style="height: 40px;margin-top: 8px;" data-v-fde0c5aa="" xmlns="http://www.w3.org/2000/svg" class="iconLeft" viewBox="0 0 40 40"><defs></defs><rect data-v-fde0c5aa="" fill="transparent" x="0" y="0" width="30px" height="30px" class="logo-background-square"></rect><defs></defs><defs data-v-fde0c5aa=""><linearGradient data-v-fde0c5aa="" gradientTransform="rotate(25)" id="4f469018-8bf8-4680-91f8-4f8b00bf450c" x1="0%" y1="0%" x2="100%" y2="0%"><stop data-v-fde0c5aa="" offset="0%" stop-color="#4E1A70" stop-opacity="1"></stop><stop data-v-fde0c5aa="" offset="100%" stop-color="#2B4CF2" stop-opacity="1"></stop></linearGradient></defs><g data-v-fde0c5aa="" id="7e90fddc-7495-4402-bf36-ccd00784c639" stroke="none" fill="url(#4f469018-8bf8-4680-91f8-4f8b00bf450c)" transform="matrix(0.9403828382492065,0,0,0.9403828382492065,0,0.96892108788788)"><path d="M0 31.984h32v-32H0v32zm2-30h28v28H2v-28z"></path><path d="M6.439 16.831h8.959l-4.48-7.741zM16.602 13.331h8.959L21.082 5.59zM6.439 26.41h8.959l-4.48-7.741zM16.602 22.91h8.959l-4.479-7.741z"></path></g></svg> -->
			<img src="/images/logo.png" style="height: 50px;"/>
		</a>
		<!-- <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button> -->

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<!-- <li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-calendar"></i>
					<span>Events</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-cog"></i>
					<span>Settings</span>
				</button>
			</li> -->
			<!-- <li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-user"></i>
					<span v-if="isLoggedIn">{{$sb.auth.session().user.email}}</span>
					<span v-else>Login</span>
				</button>
			</li> -->
		</ul>
	</div>
</template>

<script>
export default {
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		},
		isLoggedIn() {
			return this.$store.isLoggedIn();
		}
	}
}
</script>